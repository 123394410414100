export const SCHEDULE_DATES = {
  espn: {
    1: ['2024-10-22', '2024-10-27'],
    2: ['2024-10-28', '2024-11-03'],
    3: ['2024-11-04', '2024-11-10'],
    4: ['2024-11-11', '2024-11-17'],
    5: ['2024-11-18', '2024-11-24'],
    6: ['2024-11-25', '2024-12-01'],
    7: ['2024-12-02', '2024-12-08'],
    8: ['2024-12-09', '2024-12-15'],
    9: ['2024-12-16', '2024-12-22'],
    10: ['2024-12-23', '2024-12-29'],
    11: ['2024-12-30', '2025-01-05'],
    12: ['2025-01-06', '2025-01-12'],
    13: ['2025-01-13', '2025-01-19'],
    14: ['2025-01-20', '2025-01-26'],
    15: ['2025-01-27', '2025-02-02'],
    16: ['2025-02-03', '2025-02-09'],
    17: ['2025-02-10', '2025-02-23'],
    18: ['2025-02-24', '2025-03-02'],
    19: ['2025-03-03', '2025-03-09'],
    20: ['2025-03-10', '2025-03-16'],
    21: ['2025-03-17', '2025-03-23'],
    22: ['2025-03-24', '2025-03-30'],
    23: ['2025-03-31', '2025-04-06'],
    24: ['2025-04-07', '2025-04-13'],
  },
  yahoo: {
    1: ['2024-10-22', '2024-10-27'],
    2: ['2024-10-28', '2024-11-03'],
    3: ['2024-11-04', '2024-11-10'],
    4: ['2024-11-11', '2024-11-17'],
    5: ['2024-11-18', '2024-11-24'],
    6: ['2024-11-25', '2024-12-01'],
    7: ['2024-12-02', '2024-12-15'],
    8: ['2024-12-16', '2024-12-22'],
    9: ['2024-12-23', '2024-12-29'],
    10: ['2024-12-30', '2025-01-05'],
    11: ['2025-01-06', '2025-01-12'],
    12: ['2025-01-13', '2025-01-19'],
    13: ['2025-01-20', '2025-01-26'],
    14: ['2025-01-27', '2025-02-02'],
    15: ['2025-02-03', '2025-02-09'],
    16: ['2025-02-10', '2025-02-23'],
    17: ['2025-02-24', '2025-03-02'],
    18: ['2025-03-03', '2025-03-09'],
    19: ['2025-03-10', '2025-03-16'],
    20: ['2025-03-17', '2025-03-23'],
    21: ['2025-03-24', '2025-03-30'],
    22: ['2025-03-31', '2025-04-06'],
    23: ['2025-04-07', '2025-04-13'],
  },
};

export const LINEUP_SLOT_IDS = {
  PG: 0,
  SG: 1,
  SF: 2,
  PF: 3,
  C: 4,
  G: 5,
  F: 6,
  SG_SF: 7,
  G_F: 8,
  PF_C: 9,
  F_C: 10,
  UTIL: 11,
  BENCH: 12,
  IR: 13,
};

export const PRO_TEAM_IDS = {
  NONE: 0,
  ATL: 1,
  BKN: 17,
  BOS: 2,
  CHA: 30,
  CHI: 4,
  CLE: 5,
  DAL: 6,
  DEN: 7,
  DET: 8,
  GSW: 9,
  HOU: 10,
  IND: 11,
  LAC: 12,
  LAL: 13,
  MEM: 29,
  MIA: 14,
  MIL: 15,
  MIN: 16,
  NOP: 3,
  NYK: 18,
  OKC: 25,
  ORL: 19,
  PHI: 20,
  PHX: 21,
  POR: 22,
  SAC: 23,
  SAS: 24,
  TOR: 28,
  UTA: 26,
  WAS: 27,
};
